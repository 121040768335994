@mixin respond-to-small {
  @media (max-width: 576px) {
    @content;
  }
}

@mixin respond-to-medium {
  @media (max-width: 767px) {
    @content;
  }
}

@mixin respond-to-medium-large {
  @media (min-width: 768px) and (max-width: 992px) {
    @content;
  }
}

@mixin respond-to-large {
  @media (max-width: 992px) {
    @content;
  }
}

@mixin respond-to-extra-large {
  @media (max-width: 1200px) {
    @content;
  }
}

@mixin respond-to-extra-extra-large {
  @media (max-width: 1400px) {
    @content;
  }
}
