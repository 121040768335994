@import "../../assets//styles/mixins/breakpoints.scss";
@import "../../assets/styles/Colors.scss";

.projects {
  @include respond-to-extra-large {
    padding: 0 64px 0 64px;
  }

  @include respond-to-medium {
    padding: 0 24px;
  }

  &__container {
    display: flex;
    justify-content: space-between;

    img {
      height: 460px;
      object-fit: cover;
      width: -webkit-fill-available;

      @include respond-to-extra-large {
        width: 100%;
      }

      @include respond-to-large {
        width: 100%;
      }
    }

    > div {
      box-sizing: border-box;
    }

    &--mobile-version {
      display: none;

      @include respond-to-large {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 24px;
      }
    }

    &--desktop-version {
      display: flex;
      flex-direction: column;
      gap: 80px;
      margin: 0 120px 104px 120px;

      @include respond-to-extra-large {
        gap: 40px;

        margin: 0 0 64px 0;
      }

      @include respond-to-medium {
        margin: 40px 0 56px 0;
      }

      &__link {
        text-decoration: none;
        color: black;
      }
    }
  }

  &__title-container {
    margin: 0 120px 80px 120px;

    @include respond-to-extra-large {
      margin: 0 0 56px 0;
    }

    @include respond-to-large {
      text-align: center;
    }

    @include respond-to-medium {
      // margin: 0 0 0 24px;
      margin: 0;
    }

    &__title {
      font-size: 48px;
      text-align: center;
      margin: 0;
    }
  }
}

.project--right {
  display: flex;

  flex-direction: column;

  @include respond-to-extra-large {
    flex-direction: column;
  }
}

.project--left {
  display: flex;

  flex-direction: column;

  @include respond-to-extra-large {
    flex-direction: column;
  }
}

.arrow-left,
.arrow-right {
  width: 24px;
}

// .projects-list__icon-right {
//   position: absolute;
//   left: 24px;
// }

// .projects-list__icon-left {
//   position: absolute;
//   right: 24px;
// }

// .arrow-left {
//   left: 24px;
// }

// .arrow-right {
//   right: 24px;
// }
