@import "../../assets//styles//mixins/breakpoints.scss";
@import "../../assets/styles/Colors.scss";
@import "../../assets/styles/Fonts.scss";

.experience-item {
  gap: 32px;

  &__year {
    font-weight: 500;
    border-radius: 90px;
    margin-bottom: 16px;

    @include respond-to-medium {
      margin-bottom: 8px;
    }
  }
}
