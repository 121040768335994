@import "../../assets/styles/mixins/breakpoints.scss";
@import "../../assets/styles/Colors.scss";
@import "../../assets/styles/Fonts.scss";

.project-container {
  width: 100%;
  display: flex;
  flex-direction: column;

  @include respond-to-extra-extra-large {
    align-items: flex-start;
  }

  @include respond-to-medium {
    height: 100%;
  }

  @include respond-to-small {
    margin: 0;
  }

  &__information {
    display: flex;
    margin-top: 24px;
    width: 100%;
    justify-content: space-between;

    @include respond-to-large {
      flex-direction: column;
      text-align: center;
      align-items: flex-start;
    }

    &__title {
      margin: 0 0 16px;
      font-weight: 400;
      text-align: start;
    }

    &__description {
      align-content: center;
      margin: 0 0 32px 0;
    }

    &__link {
      display: flex;
      justify-content: end;
      margin: 0 16px 24px 0;

      &__project {
        text-decoration: none;
        border: none;
        font-size: 16px;
        font-weight: bold;
        cursor: pointer;
        background-color: $light-blue-color;

        svg {
          margin-right: 8px;
        }
      }
    }

    &__icons {
      display: flex;
      justify-content: space-between;
      align-items: center;

      &__technologies {
        display: flex;
        align-items: center;
        gap: 16px;
      }
    }
  }
}

.project-container__image-container {
  @include respond-to-extra-large {
    width: 100%;
  }

  img {
    width: 100%;
    height: auto;
    object-fit: cover;
    filter: brightness(70%);
  }
}

.project-container__information__discover-option {
  &:hover {
    opacity: 0.8;
    color: $green-color;
  }

  &__text {
    margin: 0;
  }
}
