// $light-light-beige-color: #fefdfc;
$light-light-beige-color: #fdfdfb;
$light-beige-color: #f8f4ee;
$beige-color: #ece2d2;

$light-blue-color: #a4b1c7;
$blue-color: #687da2;
$dark-blue-color: #4f6180;

// $green-color: #606d4e;
$green-color: #566246;
$light-green-color: #74845e;
$light-light-green-color: #6a7956;

$green-color: #566246;
$light-green-color: #6a7956;

// $brown-color: #c9ad7f;
// $dark-brown-color: #96733e;
