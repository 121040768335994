@import "../../assets//styles//mixins/breakpoints.scss";
@import "../../assets/styles/Colors.scss";

.social-media-container {
  &__link {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    &__icon {
      width: 24px;
      height: 24px;
      color: black;
    }
  }

  &__dark {
    display: flex;
    gap: 24px;

    &__link {
      &__icon {
        width: 24px;
        height: 24px;
        color: black;

        &:hover {
          opacity: 0.8;
          color: $green-color;
        }
      }
    }
  }

  &__footer-version {
    display: flex;
    gap: 24px;

    &__link {
      &__icon {
        width: 24px;
        height: 24px;
        color: black;

        &:hover {
          opacity: 0.8;
          color: $green-color;
        }
      }
    }
  }

  &__light {
    display: flex;
    align-items: center;
    gap: 48px;
    background-color: $beige-color;
    padding: 128px 120px;

    @include respond-to-extra-large {
      padding: 104px 64px;
      width: -webkit-fill-available;
      justify-content: center;
    }

    @include respond-to-large {
      gap: 56px;
    }

    @include respond-to-medium {
      padding: 104px 24px;
    }

    @include respond-to-small {
      gap: 32px;
    }

    &__title {
      font-size: 24px;
      font-style: italic;
      color: $beige-color;
    }

    &__link {
      display: flex;
      gap: 56px;
      display: inline-block;
      background-color: $light-blue-color;
      padding: 24px;
      border-radius: 50%;
      width: 24px;
      height: 24px;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;

      @include respond-to-small {
        padding: 16px;
      }

      &__icon {
        color: white;
        width: 24px;
        height: 24px;
      }
    }
  }

  &__blue {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 160px;
    margin-top: 104px;

    @include respond-to-extra-large {
      display: flex;
      flex-direction: column;
      margin-top: 64px;
      gap: 40px;
    }

    @include respond-to-medium {
      margin-top: 48px;
      gap: 40px;
    }

    &__link {
      flex: 1;
      text-decoration: none;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 24px;

      &__icon {
        color: $light-blue-color;
        width: 56px;
        height: 56px;

        @include respond-to-medium {
          width: 56px;
          height: 56px;
        }
      }

      &__word {
        color: black;
      }
    }

    @include respond-to-large {
      flex-direction: column;
    }
  }
}

// .sr-only {
//   position: absolute;
//   width: 1px;
//   height: 1px;
//   padding: 0;
//   margin: -1px;
//   overflow: hidden;
//   clip: rect(0, 0, 0, 0);
//   white-space: nowrap;
//   border: 0;
// }

.close {
  display: none;
}
