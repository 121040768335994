@import "../../assets//styles//mixins/breakpoints.scss";
@import "../../assets/styles/Colors.scss";
@import "../../assets/styles/Fonts.scss";

.presentation-container {
  display: flex;
  align-items: center;
  gap: 80px;
  background-color: $light-beige-color;
  padding-right: 120px;

  @include respond-to-extra-large {
    flex-direction: column;
    padding: 0;
    gap: 64px;
  }

  @include respond-to-large {
    margin: 0;
  }

  @include respond-to-medium {
    gap: 48px;
  }

  &__text {
    align-content: center;

    @include respond-to-extra-large {
      text-align: start;
      width: -webkit-fill-available;
      padding: 0 64px 80px 64px;
    }

    @include respond-to-medium {
      padding: 0 24px 48px 24px;
    }

    h1 {
      margin: 0 0 80px 0;

      @include respond-to-extra-large {
        margin: 0 0 56px 0;
      }

      @include respond-to-medium {
        margin: 0 0 40px 0;
      }
    }

    &__title {
      color: $beige-color;
      font-size: 48px;
      margin: 0 0 40px 0;
      font-family: "Poppins";

      @include respond-to-small {
        margin: 0 0 40px 0;
      }
    }

    &__presentation {
      color: black;

      p {
        margin: 0;
      }
    }
  }

  &__photo-container {
    height: 800px;

    @include respond-to-extra-large {
      height: auto;
    }

    &__photo {
      width: 600px;
      object-fit: cover;

      @include respond-to-extra-large {
        width: 100%;
      }
    }
  }
}
