@import "../../assets//styles//mixins/breakpoints.scss";
@import "../../assets/styles/Colors.scss";
@import "../../assets/styles/Fonts.scss";

.projects-page-container {
  margin-top: 104px;

  @include respond-to-extra-large {
    margin-top: 64px;
  }

  @include respond-to-medium {
    margin-top: 56px;
  }
}

// .projects__container {
//   padding: 0 120px 104px 120px;

//   @include respond-to-extra-large {
//     padding: 0 0 64px 0;
//   }

//   @include respond-to-medium {
//     padding: 40px 0 48px 0;
//     margin: 0;
//   }
// }

.page-projects {
  // padding: 0 104px;
  margin-top: 104px;

  @include respond-to-extra-extra-large {
    padding: 0;
  }

  @include respond-to-extra-large {
    // padding: 0 64px;
    padding: 0 64px 64px 64px;
    margin: 0;
  }

  @include respond-to-medium {
    padding: 0 24px;
    margin: 0 0 56px 0;
  }
}

// .projects__container--page-version {
//   @include respond-to-large {
//     padding: 64px;
//     flex-direction: column;
//   }

//   @include respond-to-medium {
//     padding: 48px 24px 48px 24px;
//   }
// }
