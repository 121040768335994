@import "../../assets//styles//mixins/breakpoints.scss";
@import "../../assets/styles/Fonts.scss";
@import "../../assets/styles/Colors.scss";

.reviews-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 160px;
  background-color: $light-beige-color;
  padding: 104px 120px;
  margin: 104px 120px;

  @include respond-to-extra-large {
    flex-direction: column;
    margin: 64px;
    padding: 64px;
    gap: 56px;
  }

  @include respond-to-medium {
    padding: 48px 24px;
    margin: 48px 24px;
    gap: 40px;
  }

  &__block {
    text-align: center;

    @include respond-to-extra-large {
      width: fit-content;
    }

    @include respond-to-large {
      width: auto;
    }

    &__name {
      font-family: "Libre Baskerville";
      font-weight: 400;
    }
  }

  &__content {
    @include respond-to-medium {
      text-align: center;
    }

    &__first-paragraph,
    &__second-paragraph {
      @include respond-to-medium {
        font-size: 16px;
      }
    }

    &__first-paragraph {
      margin: 0 0 24px 0;
      font-weight: 500;
    }

    &__second-paragraph {
      margin: 0;
    }

    @include respond-to-medium {
      margin: 0 24px 0 24px;
    }
  }
}
