@import "../../assets/styles/mixins/breakpoints.scss";
@import "../../assets/styles/Colors.scss";

.horizontal-line {
  display: block;
  &__right {
    border: 0.5px solid black;
    width: 142px;
    margin-top: 16px;
  }

  &__centered {
    border: 0.5px solid black;
    width: 142px;
    margin: 16px auto 0 auto;
  }

  &__centered-beige-version {
    border: 0.5px solid $beige-color;
    width: 142px;
    margin: auto auto 48px auto;
  }

  &__centered-black-version {
    border: 0.5px solid black;
    width: 142px;
    margin: auto;
  }

  &__margin-bottom-version {
    margin-bottom: 48px;
  }

  @include respond-to-extra-large {
    display: block;
    &__right {
      border: 0.5px solid white;
      width: 142px;
      margin-top: 16px;
    }

    &__centered {
      border: 0.5px solid black;
      width: 142px;
      margin: 16px auto 0 auto;
    }

    &__centered-beige-version {
      border: 0.5px solid $beige-color;
      width: 142px;
      margin: auto auto 48px auto;
    }
  }
}
