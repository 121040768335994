@import "../../assets//styles//mixins/breakpoints.scss";
@import "../../assets/styles/Colors.scss";

.button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 56px;
  border: none;
  background-color: $light-green-color;
  color: white;
  font-weight: 500;
  padding: 0 24px;
  cursor: pointer;
  margin: 0 auto;
  text-decoration: none;

  &:hover {
    opacity: 0.8;
  }
}

.margin-top-version {
  margin-top: 80px;

  @include respond-to-extra-large {
    margin-top: 56px;
  }

  @include respond-to-medium {
    margin-top: 40px;
  }
}

.button-mobile-version {
  @include respond-to-small {
    display: none;
  }
}

.button-with-no-margin {
  margin: 0;
}
