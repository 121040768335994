@import "../../assets//styles//mixins/breakpoints.scss";

.skills-page-container {
  margin: 104px 0;

  @include respond-to-extra-large {
    margin: 64px 0;
  }

  @include respond-to-medium {
    margin: 56px 0;
  }
}
