@import "../../assets//styles//mixins/breakpoints.scss";
@import "../../assets/styles/Colors.scss";

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  top: 0;
  z-index: 1000;
  padding: 24px 120px;
  position: fixed;
  width: -webkit-fill-available;
  background-color: $light-light-beige-color;

  @include respond-to-extra-extra-large {
    padding: 24px 104px;
  }

  @include respond-to-extra-large {
    padding: 24px 64px;
  }

  @include respond-to-large {
    flex-direction: row;
    position: absolute;
  }

  @include respond-to-medium {
    padding: 24px;
  }

  &__links {
    display: flex;
    align-items: center;
    gap: 40px;

    @include respond-to-large {
      gap: 24px;
    }

    &__nav {
      display: flex;
      gap: 40px;

      @include respond-to-large {
        display: none;
      }

      ul {
        display: flex;
        gap: 40px;

        li {
          list-style-type: none;

          a {
            text-decoration: none;
            cursor: pointer;
            font-size: 16px;
            font-weight: 300;
            align-self: center;
            color: black;

            &:hover {
              opacity: 0.8;
              color: $green-color;
            }
          }
        }

        @include respond-to-extra-large {
          gap: 24px;
        }
      }
    }

    &__languages {
      cursor: pointer;
      position: relative;

      &__current-language {
        display: flex;
        gap: 8px;
        align-items: center;
      }

      &__new-language {
        position: absolute;
      }
    }
  }

  &__links-block {
    display: flex;
    align-items: center;
    gap: 40px;
  }
}

.menu-hamburger {
  display: none;

  @include respond-to-large {
    display: block;
  }

  &__button {
    background: none;
    border: none;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;

    @include respond-to-large {
      height: 100%;
    }

    span {
      display: block;
      width: 100%;
      height: 1.5px;
      background-color: black;
      margin: 6px 0;
    }
  }

  &__nav {
    display: none;
    position: absolute;
    top: 40px;
    right: 0;
    border-radius: 4px;
    background-color: white;
    text-align: center;
    width: 100%;
    height: 100%;
    z-index: 3;

    &.open {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 40px;
      background-color: $light-beige-color;
    }

    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      display: flex;
      flex-direction: column;
      gap: 80px;

      li {
        cursor: pointer;
        font-size: 24px;
        font-weight: 500;

        a {
          text-decoration: none;
          color: black;

          &:hover {
            opacity: 0.8;
            color: $green-color;
          }
        }
      }
    }

    &__links {
      display: flex;
      flex-direction: row;
      justify-content: center;
      gap: 40px;
    }

    &__block {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      gap: 80px;
    }
  }
}

// .fa-xmark {
//   align-self: flex-end;
//   margin-right: 40px;
//   width: 40px;
//   height: 40px;
//   cursor: pointer;
// }

.header-mobile-version {
  background-color: $light-beige-color;

  @include respond-to-large {
    // background-color: white;
    height: 100%;
  }
}

.size-6 {
  align-self: flex-end;
  margin-right: 40px;
  width: 40px;
  height: 40px;
  cursor: pointer;
}
