@import "../../assets//styles//mixins/breakpoints.scss";
@import "../../assets/styles/Colors.scss";

.social-media-accounts {
  display: flex;
  align-items: center;
  justify-content: space-between;

  @include respond-to-large {
    flex-direction: column;
    gap: 80px;
  }

  @include respond-to-small {
    margin: 64px 56px;
  }

  &__question {
    margin: 0 0 24px 0;
    font-weight: 500;

    @include respond-to-small {
      margin: 0;
    }
  }

  &__answer {
    margin: 0;
  }

  &__question,
  &__answer {
    font-size: 24px;

    @include respond-to-medium {
      font-size: 16px;
    }
  }

  &__title {
    font-size: 24px;
    font-style: italic;
  }

  &__icons-container {
    display: flex;
    gap: 56px;

    &__icon {
      color: white;
      width: 24px;
      height: 24px;
      transition: transform 0.3s ease;

      @include respond-to-small {
        padding: 16px;
      }
    }
  }

  &__text {
    width: -webkit-fill-available;

    @include respond-to-extra-large {
      margin: 0 64px;
    }

    @include respond-to-medium {
      margin: 0 24px;
    }
  }
}

.social-media-account-large-margin {
  margin: 0 0 80px 0;
}

.home-page-version {
  margin: 128px 0 128px 120px;
  display: flex;
  justify-content: space-between;
  gap: 80px;

  @include respond-to-extra-extra-large {
    margin: 128px 0 128px 104px;
  }

  @include respond-to-extra-large {
    margin: 104px 0 0 0;
    flex-direction: column;
  }
}

.contact-page-version {
  margin: 128px 0;
  padding: 0;

  @include respond-to-extra-extra-large {
    margin: 128px 104px;
  }

  @include respond-to-medium {
    margin: 104px 80px;
  }

  @include respond-to-small {
    margin: 104px 48px;
  }
}
