@import "../../assets//styles//mixins/breakpoints.scss";
@import "../../assets/styles/Colors.scss";

.section-title {
  gap: 16px;
  text-align: start;
}

.small-margin {
  margin: 0 0 32px 0;

  @include respond-to-small {
    text-align: center;
  }
}

.large-margin {
  margin: 0 0 104px 0;

  @include respond-to-extra-large {
    margin: 0 0 56px 0;
  }

  @include respond-to-medium {
    margin: 0 0 40px 0;
  }

  // @include respond-to-small {
  //   margin: 0 0 40px 0;
  // }
}

.skills-version {
  margin: 0 240px 64px;
}

.version-with-text-align {
  text-align: center;

  // @include respond-to-extra-large {
  //   text-align: start;
  // }
}

.contact-section-mobile-version {
  text-align: center;
}

.version-with-margin-top {
  @include respond-to-extra-large {
    margin-left: 64px;
    margin-right: 64px;
  }

  @include respond-to-medium {
    margin-left: 24px;
    margin-right: 24px;
  }
}

.version-with-margin-left {
  @include respond-to-extra-large {
    margin-left: 64px;
    margin-right: 64px;
  }

  @include respond-to-medium {
    margin-left: 24px;
    margin-right: 24px;
  }
}

.home-page__title-section {
  h2 {
    text-align: center;

    @include respond-to-extra-large {
      text-align: start;
    }
  }
}
