@import "../../assets//styles//mixins/breakpoints.scss";

.skills {
  margin: 120px 104px 0 120px;

  @include respond-to-extra-large {
    margin: 0 64px 0 64px;
  }

  @include respond-to-medium {
    margin: 48px 24px 0 24px;
  }
}
