@import "../../assets//styles//mixins/breakpoints.scss";

.services-page {
  margin: 104px 0 0 0;

  @include respond-to-extra-large {
    margin: 64px 0 0 0;
  }

  @include respond-to-medium {
    margin: 56px 0 0 0;
  }

  &__title {
    @include respond-to-small {
      margin: 0;
    }
  }

  &__introduction {
    @include respond-to-extra-extra-large {
      margin-bottom: 120px;
    }

    @include respond-to-extra-large {
      margin-bottom: 64px;
    }

    @include respond-to-medium {
      margin: 0 0 56px 0;
    }
  }
}
