@import "../../assets//styles//mixins/breakpoints.scss";
@import "../../assets/styles/Colors.scss";

.technologies-list {
  display: flex;
  gap: 120px;
  margin: 112px 240px;
  justify-content: center;

  @include respond-to-extra-large {
    gap: 40px;
    margin: 0 104px;
    justify-content: space-between;
  }

  @include respond-to-large {
    flex-direction: column;
    margin: 0;
  }

  @include respond-to-medium {
    grid-template-columns: none;
  }

  @include respond-to-small {
    flex-direction: column;
  }

  &__subject {
    color: white;
    padding: 4px 14px;
    border-radius: 90px;
    font-weight: 500;
  }

  &__item {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 40px;
    width: 224px;

    @include respond-to-extra-large {
      gap: 32px;
    }

    @include respond-to-large {
      justify-content: center;
      width: 100%;
    }

    @include respond-to-medium {
      gap: 24px;
    }

    &__icon {
      color: $light-blue-color;
    }

    &__title {
      font-weight: 500;
      text-align: center;
      margin-bottom: 16px;

      @include respond-to-medium {
        margin-bottom: 8px;
      }
    }

    &__technologies {
      text-align: center;
    }
  }
}

.mobile-version {
  margin: 0;

  @include respond-to-extra-large {
    margin: 64px 0;
  }

  @include respond-to-medium {
    margin: 0;
  }
}
