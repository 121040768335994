@import "../../assets//styles//mixins/breakpoints.scss";

.project-section {
  margin: 252px 120px 104px 120px;

  @include respond-to-extra-large {
    margin: 172px 64px 64px 64px;
  }

  @include respond-to-medium {
    margin: 164px 24px 56px 24px;
  }

  &__description-block {
    margin-bottom: 56px;
  }

  &__image {
    width: -webkit-fill-available;
    margin-top: 104px;
  }
}
