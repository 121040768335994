@import "../../assets//styles//mixins/breakpoints.scss";
@import "../../assets/styles/Colors.scss";

.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin: 0 120px 24px 120px;
  color: black;

  @include respond-to-extra-extra-large {
    margin: 0 104px 24px 104px;
  }

  @include respond-to-extra-large {
    margin: 0 64px 24px 64px;
  }

  @include respond-to-large {
    flex-direction: column;
    gap: 40px;
  }

  @include respond-to-medium {
    margin: 0 24px 24px 24px;
  }

  &__block {
    display: flex;
    justify-content: space-between;
    width: 100%;

    @include respond-to-extra-large {
      flex-direction: column;
      align-items: center;
      gap: 40px;
    }

    @include respond-to-small {
      align-items: flex-start;
      width: fit-content;
    }
  }

  &__main-information {
    &__date {
      margin-top: 8px;
    }
  }

  &__links {
    display: flex;
    gap: 160px;

    @include respond-to-extra-large {
      gap: 80px;
    }

    @include respond-to-small {
      gap: 40px;
      flex-direction: column;
    }

    &__website-menu {
      h3 {
        margin-top: 0;
      }

      &__nav {
        &__list {
          list-style: none;
          margin: 0;
          padding: 0;
          cursor: pointer;

          &__link {
            margin-bottom: 8px;

            a {
              text-decoration: none;
              color: black;

              &:hover {
                opacity: 0.8;
                color: $green-color;
              }
            }
          }
        }
      }
    }

    &__social-media {
      h3 {
        margin-top: 0;
      }
    }
  }
}
