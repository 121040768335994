@import "../../assets/styles//mixins/breakpoints.scss";

.contact-page {
  margin: 104px 120px;
  display: flex;
  align-items: center;
  flex-direction: column;

  @include respond-to-extra-large {
    margin: 64px;
  }

  @include respond-to-medium {
    margin: 56px 24px;
  }
}
