@import "../../assets//styles//mixins/breakpoints.scss";
@import "../../assets/styles/Colors.scss";

// .devicon-2x {
//   font-size: 2em;

//   @include respond-to-large {
//     font-size: 1em;
//   }
// }

// .devicon-3x {
//   font-size: 3em;

//   @include respond-to-large {
//     font-size: 2em;
//   }
// }

// .devicon-4x {
//   font-size: 4em;
// }

.fa-5x {
  @include respond-to-extra-large {
    font-size: 4em;
  }
}

.devicon-small-version {
  font-size: 2em;
}
