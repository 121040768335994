@import "../../assets/styles//mixins/breakpoints.scss";
@import "../../assets/styles/Colors.scss";

.text-section {
  text-align: center;
  padding: 104px 120px;
  color: black;
  background-color: $light-beige-color;

  @include respond-to-extra-large {
    padding: 64px;
  }

  @include respond-to-medium {
    padding: 48px 24px;
  }

  img {
    width: 600px;
    height: 600px;
    object-fit: cover;
    position: absolute;
    top: 400px;
    right: 0;
  }

  &__content {
    &__text {
      margin: 0;
    }
  }
}
