@import "./mixins/breakpoints.scss";
@import "./Colors.scss";
@import "./Fonts.scss";

body {
  background-color: $light-light-beige-color;
}

* {
  font-weight: 300;
}

h1,
h2,
h3 {
  font-weight: 400;
  margin: 0;
}

h1 {
  color: $dark-blue-color;
  font-size: 48px;

  @include respond-to-medium {
    // font-size: 32px;
    font-size: 24px;
  }
}

h2 {
  color: $blue-color;
  font-size: 40px;

  @include respond-to-medium {
    // font-size: 32px;
    font-size: 24px;
  }
}

h3 {
  font-size: 32px;
  font-style: italic;
  color: $green-color;
  margin-bottom: 32px;

  @include respond-to-extra-large {
    margin-bottom: 24px;
  }

  @include respond-to-medium {
    font-size: 24px;
    margin-bottom: 16px;
  }
}

main {
  margin-top: 148px;

  @include respond-to-extra-large {
    margin-top: 108px;
  }
}

// .image-with-opacity {
//   box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
// }

// .image-with-border-radius {
//   border-radius: 4px;
// }

.homepage-button {
  width: fit-content;
  margin: 80px auto 0 auto;

  @include respond-to-extra-large {
    margin: 56px auto 0 auto;
  }

  @include respond-to-medium {
    margin: 40px auto 0 auto;
  }
}

.words-with-font-weight {
  font-weight: 600;
}

.services-list {
  padding: 104px 0;
  background-color: $light-beige-color;

  @include respond-to-extra-large {
    padding: 64px 0;
  }

  @include respond-to-medium {
    padding: 48px 0;
  }
}

.values-list-section {
  margin: 104px 120px;
  display: flex;
  flex-direction: column;
  gap: 80px;

  @include respond-to-extra-large {
    margin: 80px 64px;
    gap: 56px;
  }

  @include respond-to-medium {
    margin: 48px 24px;
    gap: 40px;
  }

  // @include respond-to-small {
  //   gap: 40px;
  // }
}
