@import "../../assets/styles//mixins/breakpoints.scss";
@import "../../assets/styles/Colors.scss";

.experiences-list {
  display: flex;
  gap: 80px;
  align-items: center;
  background-color: $light-light-beige-color;
  padding: 0 120px 0 0;

  @include respond-to-extra-large {
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    margin: 0;
    gap: 64px;
    padding: 0;
  }

  @include respond-to-large {
    flex-direction: column;
  }

  @include respond-to-medium {
    margin: 0;
    flex-direction: column;
    gap: 48px;
  }

  &__image-container {
    width: 600px;
    // height: 600px;
    // height: 700px;

    @include respond-to-extra-large {
      width: 100%;
    }

    &__image {
      width: 600px;
      // height: 700px;
      object-fit: cover;

      @include respond-to-extra-large {
        width: 100%;
        // height: 1400px;
      }

      // @include respond-to-small {
      //   height: 680px;
      // }
    }
  }

  &__texts {
    &__block {
      display: flex;
      flex-direction: column;
      gap: 32px;

      @include respond-to-medium {
        gap: 24px;
      }
    }

    @include respond-to-extra-large {
      margin: 0 64px 64px 64px;
    }

    @include respond-to-medium {
      margin: 0 24px 48px 24px;
    }
  }
}
