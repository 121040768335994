@import "../../assets//styles//mixins/breakpoints.scss";
@import "../../assets/styles/Fonts.scss";
@import "../../assets/styles/Colors.scss";

.image-and-text-block {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 80px;

  @include respond-to-extra-large {
    flex-direction: column-reverse;
    align-items: flex-start;
    gap: 64px;
  }

  @include respond-to-medium {
    gap: 48px;
  }

  &__text {
    &__title {
      margin: 0 0 32px 0;
    }
  }

  &__image-container {
    @include respond-to-extra-large {
      width: 100%;
    }

    &__image {
      width: 600px;
      object-fit: cover;

      @include respond-to-extra-large {
        width: 100%;
      }

      // @include respond-to-medium {
      //   // height: 680px;
      // }
    }
  }

  &.row-reverse-version {
    flex-direction: row-reverse;

    @include respond-to-extra-large {
      flex-direction: column-reverse;
    }
  }

  &__paragraph {
    margin: 0;
  }

  &__margin-left {
    padding-left: 120px;

    @include respond-to-extra-large {
      padding: 0 64px 64px 64px;
    }

    @include respond-to-medium {
      padding: 0 24px 48px 24px;
    }
  }

  &__margin-right {
    padding-right: 120px;
    display: flex;
    flex-direction: column;

    @include respond-to-extra-large {
      padding: 0 64px 64px 64px;
    }

    @include respond-to-medium {
      padding: 0 24px 48px 24px;
    }
  }
}
