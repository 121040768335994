@import "../../assets/styles//mixins/breakpoints.scss";
@import "../../assets/styles/Colors.scss";

.about {
  &__introduction {
    @include respond-to-extra-extra-large {
      margin-bottom: 120px;
    }

    @include respond-to-extra-large {
      margin-bottom: 64px;
    }

    @include respond-to-medium {
      margin-bottom: 0;
    }
  }

  &__development-web-section {
    padding: 128px 120px;
    background-color: $beige-color;
    text-align: center;

    @include respond-to-extra-large {
      padding: 104px;
    }

    @include respond-to-medium {
      padding: 104px 24px;
    }

    p {
      margin: 0;
    }
  }
}

.about-page {
  &__title {
    margin: 120px 104px 0 120px;

    @include respond-to-extra-large {
      margin: 64px 64px 0 64px;
    }

    @include respond-to-medium {
      margin: 56px 24px 0 24px;
    }
  }
}

.horizontal-line__about-version {
  border: 0.5px solid black;
  width: 80px;
  margin: 0 auto 32px auto;
}
