@import "../../assets//styles//mixins/breakpoints.scss";
@import "../../assets/styles/Colors.scss";

.contact-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: $light-beige-color;
  padding: 104px 120px;

  @include respond-to-extra-large {
    padding: 64px;
  }

  @include respond-to-medium {
    padding: 48px 24px;
  }

  &__question {
    text-align: center;
  }

  &__answer {
    margin: 0 0 80px 0;
    text-align: center;

    @include respond-to-extra-large {
      margin: 0 0 40px 0;
    }

    // @include respond-to-medium {
    //   margin: 0 0 40px 0;
    // }
  }
}

.margin-bottom-version {
  margin-bottom: 104px;

  @include respond-to-extra-large {
    margin: 0 0 64px 0;
  }

  @include respond-to-medium {
    margin: 0 0 56px 0;
  }
}

.contact-margin-top {
  margin-top: 0;
}
